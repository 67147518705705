import { useState } from "react";
import style from "./style.module.css";
import CloseIcon from '@mui/icons-material/Close';

import {
  Box,
  Drawer,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
} from "@mui/material";
export const Popdown = ({ text, close }) => {

  const handleClose = () => {
    // Toggle the showPopdown state when the image is clicked
    close(false)
  };
  return (
    <>
      <Grid className={style.popdown1}

        py={2}

        sx={{
          // marginTop:"400px",
          position: "sticky",
          bottom: "0",

          //  border: '1px solid blue'
        }}
      >        <div className={style.popdown}>
          <div>Place your "{text} Ad" here</div>
          <div > <CloseIcon onClick={handleClose} ></CloseIcon></div>
        </div>
      </Grid>

    </>
  );
};
